import React from 'react';
import HomeXXL from '../components/HomeXXL/HomeXXL';




const Home = () => {
  return (
      <>
      <HomeXXL />
      
    </>
  );
};

export default Home;