export const epilations = [
  {
    id: 1,
    nom: 'Sourcils',
    bis: '',
    prix: 8,
    commentaire: '',
  },
  {
    id: 2,
    nom: 'Duvet',
    bis: '',
    prix: 7,
    commentaire: '',
  },
  {
    id: 3,
    nom: 'Menton',
    bis: '',
    prix: 6,
    commentaire: '',
  },
  {
    id: 4,
    nom: 'Visage',
    bis: '',
    prix: 25,
    commentaire: '',
  },
  {
    id: 5,
    nom: 'Aisselles',
    bis: '',
    prix: 10,
    commentaire: '',
  },
  {
    id: 6,
    nom: 'Bras',
    bis: '',
    prix: 21,
    commentaire: '',
  },
  {
    id: 7,
    nom: 'Maillot',
    bis: 'simple',
    prix: 10,
    commentaire: '',
  },
  {
    id: 8,
    nom: 'Maillot',
    bis: 'échancré',
    prix: 14,
    commentaire: '',
  },
  {
    id: 9,
    nom: 'Maillot',
    bis: 'brézilien',
    prix: 18,
    commentaire: '',
  },
  {
    id: 10,
    nom: 'Maillot',
    bis: 'intégral',
    prix: 23,
    commentaire: '',
  },
  {
    id: 11,
    nom: 'Jambes',
    bis: 'complètes',
    prix: 27,
    commentaire: '',
  },
  {
    id: 12,
    nom: 'Demi-Jambes',
    bis: '',
    prix: 18,
    commentaire: '',
  },
]

export const mains = [
  {
    id: 1,
    nom: 'Manucurie',
    bis: '',
    prix: 21,
    commentaire: 'Limage, cuticules, modelage mains + avant-bras',
  },
  {
    id: 2,
    nom: 'Semi-permanent',
    bis: '',
    prix: 27,
    commentaire: 'Avec limage et cuticules : 30€ ',
  },
  {
    id: 3,
    nom: 'Dépose vernis',
    bis: '',
    prix: 8,
    commentaire: 'Dépose de vernis semi-permanent',
  },
  {
    id: 4,
    nom: 'Soin réparateur',
    bis: '',
    prix: 30,
    commentaire: 'Gommage bio, enveloppement, modelage ',
  },
  {
    id: 5,
    nom: 'Vernis classique',
    bis: '',
    prix: 10,
    commentaire: 'Manucurie + Vernis classique : 30€',
  },
]

export const makeup = [
  {
    id: 1,
    nom: 'Maquillage',
    bis: '',
    prix: 25,
    commentaire: '',
  },
  {
    id: 2,
    nom: 'Teinture cils',
    bis: '',
    prix: 15,
    commentaire: '',
  },
  {
    id: 3,
    nom: 'Teinture Sourcils',
    bis: '',
    prix: 10,
    commentaire: '',
  },
]

export const mgs = [
  {
    id: 1,
    nom: 'Modelage Corps',
    bis: 'Bio',
    prix: 58,
    temps: 1,
    commentaire: "Modelage d'une heure ( 30 minutes possible à 31€ )",
  },
  {
    id: 2,
    nom: 'Modelage Dos',
    bis: 'Bio',
    prix: 27,
    temps: 30,
    commentaire: 'Modelage de 30 minutes',
  },
  {
    id: 3,
    nom: 'Modelage Jambes',
    bis: 'Bio',
    prix: 27,
    temps: 30,
    commentaire: 'Modelage de 30 minutes',
  },
  {
    id: 4,
    nom: 'Modelage Amincissant',
    bis: 'Bio',
    prix: 63,
    temps: 1,
    commentaire: 'Modelage tonique ( forfait 12 séances : 690€  ) ',
  },
  {
    id: 5,
    nom: 'Gommage Corps',
    bis: 'Bio',
    prix: 21,
    temps: 0,
    commentaire:
      "Une douche doit être prise après le gommage afin de l'éliminer",
  },
  {
    id: 6,
    nom: 'Gommage Dos',
    bis: 'Bio',
    prix: 11,
    temps: 0,
    commentaire: 'Recommandé avant un modelage',
  },
  {
    id: 7,
    nom: 'Gommage Jambes',
    bis: 'Bio',
    prix: 11,
    temps: 0,
    commentaire: 'Recommandé avant un modelage',
  },
  {
    id: 8,
    nom: 'Gommage Amincissant',
    bis: 'Bio',
    prix: 17,
    temps: 0,
    commentaire: 'Recommandé avant un modelage',
  },
  {
    id: 9,
    nom: 'Soin Corps',
    bis: '',
    prix: 89,
    temps: 0,
    commentaire: 'Gommage, enveloppement et modelage',
  },
  {
    id: 10,
    nom: 'Soin Dos',
    bis: '',
    prix: 58,
    temps: 0,
    commentaire: 'Gommage, enveloppement et modelage',
  },
  {
    id: 11,
    nom: 'Soin Jambes',
    bis: '',
    prix: 58,
    temps: 0,
    commentaire: 'Gommage, enveloppement jambes lourdes et modelage',
  },
  {
    id: 12,
    nom: 'Soin Amincissant',
    bis: '',
    prix: 89,
    temps: 0,
    commentaire: 'Gommage, enveloppement et modelage',
  },
]

export const pieds = [
  {
    id: 1,
    nom: 'Beauté des pieds',
    bis: '',
    prix: 27,
    commentaire: 'Limage, bain de pieds, cuticules, modelage',
  },
  {
    id: 2,
    nom: 'Semi-permanent',
    bis: '',
    prix: 27,
    commentaire: 'Avec limage et cuticules : 30€',
  },
  {
    id: 3,
    nom: 'Soin Anti-callosités',
    bis: '',
    prix: 37,
    commentaire:
      'Pour les pieds de modérément à très abimés ( Résultats visibles dès la première séance )',
  },
  {
    id: 4,
    nom: 'Soin rénovateur',
    bis: '',
    prix: 30,
    commentaire:
      'Pour les pieds Légèrement abimés ( Gommage bio, enveloppement, modelage ) ',
  },
  {
    id: 5,
    nom: 'Vernis classique',
    bis: '',
    prix: 10,
    commentaire: 'Manucurie + Vernis classique : 30€',
  },
]

export const soins = [
  {
    id: 1,
    nom: 'Hydratant',
    bis: 'Bio',
    prixclassic: 32,
    tempsclassic: 0,
    prixpremium: 58,
    tempspremium: 0,
    commentaire: 'Hydratant et repulpant',
  },
  {
    id: 2,
    nom: 'Nourrissant',
    bis: 'Bio',
    prixclassic: 32,
    tempsclassic: 0,
    prixpremium: 58,
    tempspremium: 0,
    commentaire: 'Nourrissant et protecteur',
  },
  {
    id: 3,
    nom: 'Purifiant',
    bis: 'Bio',
    prixclassic: 32,
    tempsclassic: 0,
    prixpremium: 58,
    tempspremium: 0,
    commentaire: 'Purifiant et équilibrant',
  },
  {
    id: 4,
    nom: 'Détoxifiant',
    bis: 'Bio',
    prixclassic: 32,
    tempsclassic: 0,
    prixpremium: 58,
    tempspremium: 0,
    commentaire: 'Detoxifiant et oxygénant ',
  },
  {
    id: 5,
    nom: 'Repulpant',
    bis: 'Bio',
    prixclassic: 37,
    tempsclassic: 0,
    prixpremium: 70,
    tempspremium: 0,
    commentaire: 'Raffermissant et tonifiant ',
  },
  {
    id: 6,
    nom: 'Collagène',
    bis: '',
    prixclassic: null,
    tempsclassic: 0,
    prixpremium: 80,
    tempspremium: 0,
    commentaire: 'Atténuation visible des rides et hydratation intense ',
  },
  {
    id: 7,
    nom: 'Beaux Yeux',
    bis: '',
    prixclassic: 13,
    tempsclassic: 0,
    prixpremium: null,
    tempspremium: 0,
    commentaire: "En complément d'un soin visage",
  },
]
