import React from 'react'
import Corps from '../components/Menu/Corps'


function Contact() {
    return (    
        <>
        {/* <motion.div
        
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        > */}
        <Corps />
        {/* </motion.div> */}

        </>
    );
};

export default Contact;